<template>
  <div class="p16">
    <a-row :gutter="[16,16]">
      <a-col :span="6">
        <a-card title="计数器" class="text-center">
          <count-to
            ref="countTo"
            class="text-primary txt-40"
            :start-val="form.startVal"
            :end-val="form.endVal"
            :duration="form.duration"
            :decimals="form.decimals"
            :separator="form.separator"
            :prefix="form.prefix"
            :suffix="form.suffix"
            :autoplay="false" />
          <a-form-data ref="form" v-model="form" :setting="{ labelCol: { span: 8 }, wrapperCol: { span: 10 } }" :render-data="renderData" />
          <a-button @click="start">开始</a-button>
          <a-button @click="pause">暂停</a-button>
        </a-card>
      </a-col>
      <a-col :span="6">
        <a-card title="文字转二维码" class="text-center">
          <!--<div>http://www.baidu.com</div>-->
          <!--<qr-code str="http://www.baidu.com" :height="200" :width="200" class="flex justify-center" />-->
        </a-card>
      </a-col>
      <a-col :span="12" class="flex">
        <a-card title="动画" class="wper50">
          <a-form-model>
            <a-form-model-item label="进入动画">
              <a-select v-model="animateIn">
                <a-select-opt-group v-for="(item, index) in animateList" :key="index">
                  <span slot="label">{{ item.label }}</span>
                  <a-select-option v-for="(v, k) in item.options" :key="k" :value="v.value">{{ v.label }}</a-select-option>
                </a-select-opt-group>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="隐藏动画">
              <a-select v-model="animateOut">
                <a-select-opt-group v-for="(item, index) in animateList" :key="index">
                  <span slot="label">{{ item.label }}</span>
                  <a-select-option v-for="(v, k) in item.options" :key="k" :value="v.value">{{ v.label }}</a-select-option>
                </a-select-opt-group>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="显示隐藏">
              <a-button @click="flag = !flag">{{ flag ? '隐藏' : '显示' }}</a-button>
            </a-form-model-item>
          </a-form-model>
          <div class="w100 h100">
            <transition :enter-active-class="`animate__animated ${animateIn}`" :leave-active-class="`animate__animated ${animateOut}`">
              <div v-if="flag" class="w100 h100 bg-primary" />
            </transition>
          </div>
        </a-card>
        <a-card title="动画" class="wper50">
          <a-form-model>
            <a-form-model-item label="重复动画">
              <a-select v-model="animate">
                <a-select-opt-group v-for="(item, index) in animateList" :key="index">
                  <span slot="label">{{ item.label }}</span>
                  <a-select-option v-for="(v, k) in item.options" :key="k" :value="v.value">{{ v.label }}</a-select-option>
                </a-select-opt-group>
              </a-select>
            </a-form-model-item>
          </a-form-model>
          <div :class="`w100 h100 bg-primary animate__animated ${animate} animate__infinite`" />
          <div>
            <a-button icon="link" type="link"><a href="https://animate.style" target="_blank">https://animate.style</a></a-button>
            <div class="text-black">停顿几秒后执行</div>
            animate__delay-2s	2s<br>
            animate__delay-3s	3s<br>
            animate__delay-4s	4s<br>
            animate__delay-5s	5s<br>
            <div class="text-black">执行频率</div>
            animate__slow	2s<br>
            animate__slower	3s<br>
            animate__fast	800ms<br>
            animate__faster	500ms<br>
            <div class="text-black">重复执行</div>
            animate__repeat-1	1次<br>
            animate__repeat-2	2次<br>
            animate__repeat-3	3次<br>
            animate__infinite	infinite 重复
          </div>
        </a-card>
      </a-col>
    </a-row>
    <div>
      <a-button @click="enCode">加密</a-button>
      <a-button @click="deCode">解密</a-button>
    </div>
  </div>
</template>
<script>
  import countTo from 'vue-count-to'
  import CryptoJS from "crypto-js"
  export default {
    name: 'component',
    components: { countTo },
    data() {
      return {
        form: {
          startVal: 0,
          endVal: 2017,
          duration: 4000,
          decimals: 0,
          separator: ',',
          prefix: '¥ ',
          suffix: ' 元'
        },
        renderData: {
          startVal: { label: '开始', renderName: 'aInputNumber', props: { style: 'width: 100%' } },
          endVal: { label: '结束', renderName: 'aInputNumber', props: { style: 'width: 100%' } },
          duration: { label: '运行时间', renderName: 'aInputNumber', props: { style: 'width: 100%' } },
          decimals: { label: '小数点后几位', renderName: 'aInputNumber', props: { style: 'width: 100%' } },
          separator: { label: '分隔符' },
          prefix: { label: '前缀' },
          suffix: { label: '后缀' },
        },
        animate: 'animate__pulse',
        animateIn: 'animate__zoomInLeft',
        animateOut: 'animate__zoomOutRight',
        flag: true,
        animateList: [
          {
            label: 'Attention seekers',
            options: [
              { label: '弹跳 bounce', value: 'animate__bounce' },
              { label: '闪光 flash', value: 'animate__flash' },
              { label: '脉冲 pulse', value: 'animate__pulse' },
              { label: '橡皮筋 rubberBand', value: 'animate__rubberBand' },
              { label: '摇动X shakeX', value: 'animate__shakeX' },
              { label: '摇摇 shakeY', value: 'animate__shakeY' },
              { label: '摇头 headShake', value: 'animate__headShake' },
              { label: '摇摆 swing', value: 'animate__swing' },
              { label: '多田 tada', value: 'animate__tada' },
              { label: '摇晃 wobble', value: 'animate__wobble' },
              { label: '果冻 jello', value: 'animate__jello' },
              { label: '心跳 heartBeat', value: 'animate__heartBeat' }
            ]
          },
          {
            label: 'Back entrances',
            options: [
              { label: 'backInDown', value: 'animate__backInDown' },
              { label: 'backInLeft', value: 'animate__backInLeft' },
              { label: 'backInRight', value: 'animate__backInRight' },
              { label: 'backInUp', value: 'animate__backInUp' }
            ]
          },
          {
            label: 'Back exits',
            options: [
              { label: 'backOutDown', value: 'animate__backOutDown' },
              { label: 'backOutLeft', value: 'animate__backOutLeft' },
              { label: 'backOutRight', value: 'animate__backOutRight' },
              { label: 'backOutUp', value: 'animate__backOutUp' }
            ]
          },
          {
            label: 'Bouncing entrances',
            options: [
              { label: 'bounceIn', value: 'animate__bounceIn' },
              { label: 'bounceInDown', value: 'animate__bounceInDown' },
              { label: 'bounceInLeft', value: 'animate__bounceInLeft' },
              { label: 'bounceInRight', value: 'animate__bounceInRight' },
              { label: 'bounceInUp', value: 'animate__bounceInUp' }
            ]
          },
          {
            label: 'Bouncing exits',
            options: [
              { label: 'bounceOut', value: 'animate__bounceOut' },
              { label: 'bounceOutDown', value: 'animate__bounceOutDown' },
              { label: 'bounceOutLeft', value: 'animate__bounceOutLeft' },
              { label: 'bounceOutRight', value: 'animate__bounceOutRight' },
              { label: 'bounceOutUp', value: 'animate__bounceOutUp' }
            ]
          },
          {
            label: 'Fading entrances',
            options: [
              { label: 'fadeIn', value: 'animate__fadeIn' },
              { label: 'fadeInDown', value: 'animate__fadeInDown' },
              { label: 'fadeInDownBig', value: 'animate__fadeInDownBig' },
              { label: 'fadeInLeft', value: 'animate__fadeInLeft' },
              { label: 'fadeInLeftBig', value: 'animate__fadeInLeftBig' },
              { label: 'fadeInRight', value: 'animate__fadeInRight' },
              { label: 'fadeInRightBig', value: 'animate__fadeInRightBig' },
              { label: 'fadeInUp', value: 'animate__fadeInUp' },
              { label: 'fadeInUpBig', value: 'animate__fadeInUpBig' },
              { label: 'fadeInTopLeft', value: 'animate__fadeInTopLeft' },
              { label: 'fadeInTopRight', value: 'animate__fadeInTopRight' },
              { label: 'fadeInBottomLeft', value: 'animate__fadeInBottomLeft' },
              { label: 'fadeInBottomRight', value: 'animate__fadeInBottomRight' }
            ]
          },
          {
            label: 'Fading exits',
            options: [
              { label: 'fadeOut', value: 'animate__fadeOut' },
              { label: 'fadeOutDown', value: 'animate__fadeOutDown' },
              { label: 'fadeOutDownBig', value: 'animate__fadeOutDownBig' },
              { label: 'fadeOutLeft', value: 'animate__fadeOutLeft' },
              { label: 'fadeOutLeftBig', value: 'animate__fadeOutLeftBig' },
              { label: 'fadeOutRight', value: 'animate__fadeOutRight' },
              { label: 'fadeOutRightBig', value: 'animate__fadeOutRightBig' },
              { label: 'fadeOutUp', value: 'animate__fadeOutUp' },
              { label: 'fadeOutUpBig', value: 'animate__fadeOutUpBig' },
              { label: 'fadeOutTopLeft', value: 'animate__fadeOutTopLeft' },
              { label: 'fadeOutTopRight', value: 'animate__fadeOutTopRight' },
              { label: 'fadeOutBottomLeft', value: 'animate__fadeOutBottomLeft' },
              { label: 'fadeOutBottomRight', value: 'animate__fadeOutBottomRight' }
            ]
          },
          {
            label: 'Flippers',
            options: [
              { label: 'flip', value: 'animate__flip' },
              { label: 'flipInX', value: 'animate__flipInX' },
              { label: 'flipInY', value: 'animate__flipInY' },
              { label: 'flipOutX', value: 'animate__flipOutX' },
              { label: 'flipOutY', value: 'animate__flipOutY' }
            ]
          },
          {
            label: 'Lightspeed',
            options: [
              { label: 'lightSpeedInRight', value: 'animate__lightSpeedInRight' },
              { label: 'lightSpeedInLeft', value: 'animate__lightSpeedInLeft' },
              { label: 'lightSpeedOutRight', value: 'animate__lightSpeedOutRight' },
              { label: 'lightSpeedOutLeft', value: 'animate__lightSpeedOutLeft' }
            ]
          },
          {
            label: 'Rotating entrances',
            options: [
              { label: 'rotateIn', value: 'animate__rotateIn' },
              { label: 'rotateInDownLeft', value: 'animate__rotateInDownLeft' },
              { label: 'rotateInDownRight', value: 'animate__rotateInDownRight' },
              { label: 'rotateInUpLeft', value: 'animate__rotateInUpLeft' },
              { label: 'rotateInUpRight', value: 'animate__rotateInUpRight' }
            ]
          },
          {
            label: 'Rotating exits',
            options: [
              { label: 'rotateOut', value: 'animate__rotateOut' },
              { label: 'rotateOutDownLeft', value: 'animate__rotateOutDownLeft' },
              { label: 'rotateOutDownRight', value: 'animate__rotateOutDownRight' },
              { label: 'rotateOutUpLeft', value: 'animate__rotateOutUpLeft' },
              { label: 'rotateOutUpRight', value: 'animate__rotateOutUpRight' }
            ]
          },
          {
            label: 'Specials',
            options: [
              { label: 'hinge', value: 'animate__hinge' },
              { label: 'jackInTheBox', value: 'animate__jackInTheBox' },
              { label: 'rollIn', value: 'animate__rollIn' },
              { label: 'rollOut', value: 'animate__rollOut' }
            ]
          },
          {
            label: 'Zooming entrances',
            options: [
              { label: 'zoomIn', value: 'animate__zoomIn' },
              { label: 'zoomInDown', value: 'animate__zoomInDown' },
              { label: 'zoomInLeft', value: 'animate__zoomInLeft' },
              { label: 'zoomInRight', value: 'animate__zoomInRight' },
              { label: 'zoomInUp', value: 'animate__zoomInUp' }
            ]
          },
          {
            label: 'Zooming exits',
            options: [
              { label: 'zoomOut', value: 'animate__zoomOut' },
              { label: 'zoomOutDown', value: 'animate__zoomOutDown' },
              { label: 'zoomOutLeft', value: 'animate__zoomOutLeft' },
              { label: 'zoomOutRight', value: 'animate__zoomOutRight' },
              { label: 'zoomOutUp', value: 'animate__zoomOutUp' }
            ]
          },
          {
            label: 'Sliding entrances',
            options: [
              { label: 'slideInDown', value: 'animate__slideInDown' },
              { label: 'slideInLeft', value: 'animate__slideInLeft' },
              { label: 'slideInRight', value: 'animate__slideInRight' },
              { label: 'slideInUp', value: 'animate__slideInUp' }
            ]
          },
          {
            label: 'Sliding exits',
            options: [
              { label: 'slideOutDown', value: 'animate__slideOutDown' },
              { label: 'slideOutLeft', value: 'animate__slideOutLeft' },
              { label: 'slideOutRight', value: 'animate__slideOutRight' },
              { label: 'slideOutUp', value: 'animate__slideOutUp' }
            ]
          }
        ],
      }
    },
    mounted() {

    },
    methods: {
      start() {
        this.$refs.countTo.start()
      },
      pause() {
        this.$refs.countTo.pauseResume()
      },

      deCode() {
        let data = 'zYqaq9sDqPAHcJ3niv7jAg=='
        let key = 'GftZqNEoBVdB2kwx';
        let iv = '3zyJFPEzh5rUeUNi';
        let encryptData = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(key), {
          iv: CryptoJS.enc.Utf8.parse(iv),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        })
        // 解密数据转成UTF8
        console.log(encryptData.toString(CryptoJS.enc.Utf8));
      },

      enCode() {
        // 数据
        let data = { a: 1, b: 2 };
        // 16位密钥
        let key = 'GftZqNEoBVdB2kwx';
        // 16位iv
        let iv = this.generateKey(16);
        // 加密方式使用CBC，padding偏移量设置成Pkcs7，以便和后端对应解密
        let encryptData = CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS.enc.Utf8.parse(key), {
          iv: CryptoJS.enc.Utf8.parse(iv),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        })

        console.log(key, iv.toString(), encryptData.toString())
        this.$common.copyText(encryptData.toString())
      },

      generateKey(number) {
        let library = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
        let key = ""
        for (let i = 0; i < number; i++) {
          let randomPoz = Math.floor(Math.random() * library.length)
          key += library.substring(randomPoz, randomPoz + 1)
        }
        return key
      },
    }
  }
</script>